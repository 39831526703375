import { storeToRefs } from 'pinia'
import { IProvider } from '~/types/lobby.type'
import { PAGE_URLS } from '~/config/page-url'
import { useGameStore } from '~~/store/game'
interface IHomeSports {
  title: string
  image: string
  image_mb: string
  image_ncc?: string
  link?: string
  jackpot?: string
}
export const getGameCenter = () => {
  const { $config, $pinia } = useNuxtApp()
  const storeGame = useGameStore($pinia)
  const { providerGames, providerCasino } = storeToRefs(storeGame)
  return {
    HOME_SPORTS: [
      {
        title: 'THỂ THAO',
        image: 'assets/images/home/home-sport.webp',
        image_mb: 'assets/images/home/home-sport-mb.webp',
        image_ncc: 'assets/images/home/home-sport-ncc.webp',
        link: '/the-thao'
      },
      {
        title: 'game bài',
        image: 'assets/images/home/home-game-bai.webp',
        image_mb: 'assets/images/home/home-game-bai-mb.webp',
        link: '/game/game-bai'
      },
      {
        title: 'nổ hũ',
        image: 'assets/images/home/home-nohu.webp',
        image_mb: 'assets/images/home/home-nohu-mb.webp',
        link: '/game/no-hu',
        jackpot: 'jackpotNohu'
      },
      {
        title: 'lô đề',
        image: 'assets/images/home/home-lode.webp',
        image_mb: 'assets/images/home/home-lode-mb.webp',
        link: '/game/lo-de'
      },
      {
        title: 'table games',
        image: 'assets/images/home/home-table-game.webp',
        image_mb: 'assets/images/home/home-table-game-mb.webp',
        link: '/game/table-game'
      },
      {
        title: 'game nhanh',
        image: 'assets/images/home/home-game-nhanh.webp',
        image_mb: 'assets/images/home/home-game-nhanh-mb.webp',
        link: '/game/game-nhanh'
      },
      {
        title: 'bắn cá',
        image: 'assets/images/home/home-ban-ca.webp',
        image_mb: 'assets/images/home/home-ban-ca-mb.webp',
        link: '/game/ban-ca',
        jackpot: 'jackpotFishing'
      },
      {
        title: 'LIVE CASINO',
        image: 'assets/images/home/home-casino.webp',
        image_mb: 'assets/images/home/home-casino-mb.webp',
        link: PAGE_URLS.CASINO + '/' + (providerCasino?.value[0] as IProvider)?.alias
      }
    ],
    HOME_SPORTS_MB: [
      {
        title: 'THỂ THAO',
        image: 'assets/images/home/home-sport.webp',
        image_mb: 'assets/images/home/home-sport-mb.webp',
        image_ncc: 'assets/images/home/home-sport-ncc-mb.webp',
        link: '/the-thao'
      },
      {
        title: 'LIVE CASINO',
        image: 'assets/images/home/home-casino.webp',
        image_mb: 'assets/images/home/home-casino-mb.webp',
        link: PAGE_URLS.CASINO + '/' + (providerCasino?.value[0] as IProvider)?.alias
      },
      {
        title: 'game bài',
        image: 'assets/images/home/home-game-bai.webp',
        image_mb: 'assets/images/home/home-game-bai-mb.webp',
        link: '/game/game-bai'
      },
      {
        title: 'nổ hũ',
        image: 'assets/images/home/home-nohu.webp',
        image_mb: 'assets/images/home/home-nohu-mb.webp',
        link: '/game/no-hu',
        jackpot: 'jackpotNohu'
      },
      {
        title: 'lô đề',
        image: 'assets/images/home/home-lode.webp',
        image_mb: 'assets/images/home/home-lode-mb.webp',
        link: '/game/lo-de'
      },
      {
        title: 'table games',
        image: 'assets/images/home/home-table-game.webp',
        image_mb: 'assets/images/home/home-table-game-mb.webp',
        link: '/game/table-game'
      },
      {
        title: 'game nhanh',
        image: 'assets/images/home/home-game-nhanh.webp',
        image_mb: 'assets/images/home/home-game-nhanh-mb.webp',
        link: '/game/game-nhanh'
      },
      {
        title: 'bắn cá',
        image: 'assets/images/home/home-ban-ca.webp',
        image_mb: 'assets/images/home/home-ban-ca-mb.webp',
        link: '/game/ban-ca',
        jackpot: 'jackpotFishing'
      }
    ]
  }
}
export const HOME_SPORTS_MB: IHomeSports[] = [
  {
    title: 'THỂ THAO',
    image: 'assets/images/home/home-sport.webp',
    image_mb: 'assets/images/home/home-sport-mb.webp',
    image_ncc: 'assets/images/home/home-sport-ncc-mb.webp',
    link: '/the-thao'
  },
  {
    title: 'LIVE CASINO',
    image: 'assets/images/home/home-casino.webp',
    image_mb: 'assets/images/home/home-casino-mb.webp',
    link: '/livecasino'
  },
  {
    title: 'game bài',
    image: 'assets/images/home/home-game-bai.webp',
    image_mb: 'assets/images/home/home-game-bai-mb.webp',
    link: '/game/game-bai'
  },
  {
    title: 'nổ hũ',
    image: 'assets/images/home/home-nohu.webp',
    image_mb: 'assets/images/home/home-nohu-mb.webp',
    link: '/game/no-hu',
    jackpot: 'jackpotNohu'
  },
  {
    title: 'lô đề',
    image: 'assets/images/home/home-lode.webp',
    image_mb: 'assets/images/home/home-lode-mb.webp',
    link: '/game/lo-de'
  },
  {
    title: 'table games',
    image: 'assets/images/home/home-table-game.webp',
    image_mb: 'assets/images/home/home-table-game-mb.webp',
    link: '/game/table-game'
  },
  {
    title: 'game nhanh',
    image: 'assets/images/home/home-game-nhanh.webp',
    image_mb: 'assets/images/home/home-game-nhanh-mb.webp',
    link: '/game/game-nhanh'
  },
  {
    title: 'bắn cá',
    image: 'assets/images/home/home-ban-ca.webp',
    image_mb: 'assets/images/home/home-ban-ca-mb.webp',
    link: '/game/ban-ca',
    jackpot: 'jackpotFishing'
  }
]
export const HOME_LIVE_CASINO_THUMBS = [
  {
    id: '1',
    src: '/assets/images/home/casino/thumb-evo.webp',
    alt: 'Image 1',
    link: '/livecasino/all'
  },
  {
    id: '2',
    src: '/assets/images/home/casino/thumb-mg.webp',
    alt: 'Image 2',
    link: '/livecasino/all'
  },
  {
    id: '3',
    src: '/assets/images/home/casino/thumb-evo.webp',
    alt: 'Image 3',
    link: '/livecasino/all'
  }
]
export const HOME_LIVE_CASINO_EVO = [
  {
    title: 'Baccarat',
    image: '',
    link: '/livecasino/baccarat?partner=evo'
  },
  {
    title: 'Blackjack',
    image: '',
    link: '/livecasino/blackjack?partner=evo'
  },
  {
    title: 'Roulette',
    image: '',
    link: '/livecasino/roulette?partner=evo'
  },
  {
    title: 'Poker',
    image: '',
    link: '/livecasino/poker?partner=evo'
  },
  {
    title: 'Sicbo',
    image: '',
    link: '/livecasino/sicbo?partner=evo'
  },
  {
    title: 'TV show',
    image: '',
    link: '/livecasino/tv-show?partner=evo'
  }
]
export const HOME_LIVE_CASINO_MG = [
  {
    title: 'Tài xỉu',
    image: '',
    link: '/livecasino/tai-xiu?partner=go'
  },
  {
    title: 'Xóc đĩa',
    image: '',
    link: '/livecasino/xoc-dia?partner=go'
  },
  {
    title: 'Bầu cua',
    image: '',
    link: '/livecasino/bau-cua?partner=go'
  }
]
